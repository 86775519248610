// // // 

// // import React, { useState, useEffect } from 'react';
// // import Select from 'react-select';
// // import axios from 'axios';
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

// // const FdBond = () => {
// //   const [percentage, setPercentage] = useState(0);
// //   const [branches, setBranches] = useState([]);
// //   const [selectedBranch, setSelectedBranch] = useState(null);

// //   useEffect(() => {
// //     axios.get("https://api.malabarbank.in/api/branches")
// //       .then((response) => {
// //         setBranches(response.data.map(branch => ({
// //           value: branch.branchCode,
// //           label: branch.branch_name
// //         })));
// //       })
// //       .catch((error) => console.error("Error fetching branches:", error));
// //   }, []);

// //   const handleIncrease = () => {
// //     setPercentage(prev => prev + 1);
// //   };

// //   const handleDecrease = () => {
// //     setPercentage(prev => (prev > 0 ? prev - 1 : 0));
// //   };

// //   const handleInputChange = (e) => {
// //     const value = parseInt(e.target.value);
// //     if (!isNaN(value) && value >= 0) {
// //       setPercentage(value);
// //     }
// //   };


// //   const handleSubmit = () => {
// //     if (!selectedBranch) {
// //       alert('Please select a branch.');
// //       return;
// //     }
  
// //     const postData = {
// //       percentage: percentage,
// //       branchCode: selectedBranch.value,
// //       branchName: selectedBranch.label // Assuming the label contains the branch name
// //     };
  
// //     axios.post('https://api.malabarbank.in/api/fd-bond', postData)
// //       .then((response) => {
// //         alert(response.data.message); // Assuming the response contains a 'message' field
// //       })
// //       .catch((error) => {
// //         console.error("Error submitting data:", error);
// //         alert('An error occurred. Please try again.');
// //       });
// //   };
  

// //   return (
// //    <div >
// //    <div>
// //    <div className='row'>

// // <div className='col-4 bg-dark'>
// // <div className="d-flex justify-content-center mt-5">
// // <div className="card p-4 shadow-lg" style={{ width: '300px', borderRadius: '10px', border: '1px solid #ccc', backgroundColor: '#f8f9fa' }}>
// //   <h4 className="text-center mb-4">FD Bond Percentage</h4>
// //   <div className="input-group mb-3">
// //     <input 
// //       type="number" 
// //       className="form-control" 
// //       value={percentage} 
// //       onChange={handleInputChange}
// //       placeholder="Enter Percentage"
// //       min="0"
// //       style={{ height: '38px' }} // Matching height of React Select
// //     />
// //   </div>
// //   <Select
// //     className="mb-3"
// //     value={selectedBranch}
// //     onChange={setSelectedBranch}
// //     options={branches}
// //     placeholder="Select Branch"
// //     styles={{
// //       control: (base) => ({
// //         ...base,
// //         height: '38px' // Matching height of the input field
// //       })
// //     }}
// //   />
// //   <button className="btn btn-primary w-100" onClick={handleSubmit}>Submit</button>
// // </div>
// // </div>
// // </div>
// // <div className='col-8 bg-warning'>
// //   xxx
// // </div>

// // </div>
// //    </div>

// //    </div>
// //   );
// // };

// // export default FdBond;


// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

// const FdBond = () => {
//   const [percentage, setPercentage] = useState(0);
//   const [branches, setBranches] = useState([]);
//   const [selectedBranch, setSelectedBranch] = useState(null);
//   const [branchNames, setBranchNames] = useState([]);

//   useEffect(() => {
//     axios.get("https://api.malabarbank.in/api/branches")
//       .then((response) => {
//         const branchData = response.data.map(branch => ({
//           value: branch.branchCode,
//           label: branch.branch_name
//         }));
//         setBranches(branchData);
//         setBranchNames(response.data.map(branch => branch.branch_name));
//       })
//       .catch((error) => console.error("Error fetching branches:", error));
//   }, []);

//   const handleIncrease = () => {
//     setPercentage(prev => prev + 1);
//   };

//   const handleDecrease = () => {
//     setPercentage(prev => (prev > 0 ? prev - 1 : 0));
//   };

//   const handleInputChange = (e) => {
//     const value = parseInt(e.target.value);
//     if (!isNaN(value) && value >= 0) {
//       setPercentage(value);
//     }
//   };

//   const handleSubmit = () => {
//     if (!selectedBranch) {
//       alert('Please select a branch.');
//       return;
//     }

//     const postData = {
//       percentage: percentage,
//       branchCode: selectedBranch.value,
//       branchName: selectedBranch.label // Assuming the label contains the branch name
//     };

//     axios.post('https://api.malabarbank.in/api/fd-bond', postData)
//       .then((response) => {
//         alert(response.data.message); // Assuming the response contains a 'message' field
//       })
//       .catch((error) => {
//         console.error("Error submitting data:", error);
//         alert('An error occurred. Please try again.');
//       });
//   };

//   return (
//     <div>
//       <div className='row'>
//         <div className='col-3 bg-dark'>
//           <div className="d-flex justify-content-center mt-5">
//             <div className="card p-4 shadow-lg" style={{ width: '300px', borderRadius: '10px', border: '1px solid #ccc', backgroundColor: '#f8f9fa' }}>
//               <h4 className="text-center mb-4">FD Bond Percentage</h4>
//               <div className="input-group mb-3">
//                 <input 
//                   type="number" 
//                   className="form-control" 
//                   value={percentage} 
//                   onChange={handleInputChange}
//                   placeholder="Enter Percentage"
//                   min="0"
//                   style={{ height: '38px' }} // Matching height of React Select
//                 />
//               </div>
//               <Select
//                 className="mb-3"
//                 value={selectedBranch}
//                 onChange={setSelectedBranch}
//                 options={branches}
//                 placeholder="Select Branch"
//                 styles={{
//                   control: (base) => ({
//                     ...base,
//                     height: '38px' // Matching height of the input field
//                   })
//                 }}
//               />
//               <button className="btn btn-primary w-100" onClick={handleSubmit}>Submit</button>
//             </div>
//           </div>
//         </div>
//         <div className='col-9 bg-warning'>
         
//          <div className='row'>
//             <div className='col-3'>
//             <div className="p-4  text-center ">
//             {branchNames.map((name, index) => (
//               <div key={index} className="p-2 mb-2 border rounded bg-light branch-name-box">
//                 {name}
//               </div>
//             ))}
//           </div>
//             </div>
//             <div className='col-4'>

//             </div>
//          </div>
//         </div>
//       </div>
//       <style>
//         {`
//           .branch-name-box:hover {
//             background-color: #d3d3d3;
//             cursor: pointer;
//           }
//         `}
//       </style>
//     </div>
//   );
// };

// export default FdBond;


// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const FdBond = () => {
//   const [percentage, setPercentage] = useState(0);
//   const [branches, setBranches] = useState([]);
//   const [selectedBranch, setSelectedBranch] = useState(null);
//   const [branchNames, setBranchNames] = useState([]);
//   const [fdBranchReqData, setFdBranchReqData] = useState([]);
//   const [selectedBranchData, setSelectedBranchData] = useState([]);
//   const [selectedFdBond, setSelectedFdBond] = useState(null);


//   useEffect(() => {
//     axios.get("https://api.malabarbank.in/api/branches")
//       .then((response) => {
//         const branchData = response.data.map(branch => ({
//           value: branch.branchCode,
//           label: branch.branch_name
//         }));
//         setBranches(branchData);
//         setBranchNames(response.data.map(branch => branch.branch_name));
//       })
//       .catch((error) => console.error("Error fetching branches:", error));

//     axios.get("https://api.malabarbank.in/api/fdBranchReq")
//       .then((response) => {
//         if (response.data && Array.isArray(response.data.data)) {
//           console.log(response.data.data, "fdBranchReqDatayyyyy");
//           setFdBranchReqData(response.data.data);
//           console.log(fdBranchReqData,"fdBranchReqDataxxx");
//         } else {
//           console.error("Expected an array from the API but got", typeof response.data);
//         }
//       })
//       .catch((error) => console.error("Error fetching FD branch requests:", error));
//   }, []);

//   const handleInputChange = (e) => {
//     const value = parseInt(e.target.value);
//     if (!isNaN(value) && value >= 0) {
//       setPercentage(value);
//     }
//   };

//   const handleSubmit = () => {
//     if (!selectedBranch) {
//       alert('Please select a branch.');
//       return;
//     }

//     const postData = {
//       percentage: percentage,
//       branchCode: selectedBranch.value,
//       branchName: selectedBranch.label // Assuming the label contains the branch name
//     };

//     axios.post('https://api.malabarbank.in/api/fd-bond', postData)
//       .then((response) => {
//         alert(response.data.message); // Assuming the response contains a 'message' field
//       })
//       .catch((error) => {
//         console.error("Error submitting data:", error);
//         alert('An error occurred. Please try again.');
//       });
//   };

//   const handleBranchClick = (branchName) => {
//     console.log(handleBranchClick,"handleBranchClick")
//     console.log("fdBranchReqData",fdBranchReqData);
//     const filteredData = fdBranchReqData.filter(data => data.branch_name === branchName);
//     setSelectedBranchData(filteredData);
//     console.log(selectedBranchData,"setSelectedBranchData");
//   };

//   const handleApprove = (id) => {
//     // Add functionality to approve the FD bond request
//     alert(`Approved FD Bond with ID: ${id}`);
//   };

//   const handleView = (id) => {
//     console.log("id",id);
//     console.log("UMFI");
//     const fdBond = fdBranchReqData.find(data => data._id === id);
//     setSelectedFdBond(fdBond);
//     console.log(selectedFdBond,"SelectedFdBond");
//   };
  

//   const handleClose = (id) => {
//     // Add functionality to close the FD bond request card
//     setSelectedBranchData(prev => prev.filter(data => data._id !== id));
//   };

//   return (
//     <div>
//       <div className='row'>
//         <div className='col-3 bg-dark'>
//           <div className="d-flex justify-content-center mt-5">
//             <div className="card p-4 shadow-lg" style={{ width: '300px', borderRadius: '10px', border: '1px solid #ccc', backgroundColor: '#f8f9fa' }}>
//               <h4 className="text-center mb-4">FD Bond Percentage</h4>
//               <div className="input-group mb-3">
//                 <input 
//                   type="number" 
//                   className="form-control" 
//                   value={percentage} 
//                   onChange={handleInputChange}
//                   placeholder="Enter Percentage"
//                   min="0"
//                   style={{ height: '38px' }} // Matching height of React Select
//                 />
//               </div>
//               <Select
//                 className="mb-3"
//                 value={selectedBranch}
//                 onChange={setSelectedBranch}
//                 options={branches}
//                 placeholder="Select Branch"
//                 styles={{
//                   control: (base) => ({
//                     ...base,
//                     height: '38px' // Matching height of the input field
//                   })
//                 }}
//               />
//               <button className="btn btn-primary w-100" onClick={handleSubmit}>Submit</button>
//             </div>
//           </div>
//         </div>
//         <div className='col-2 bg-warning'>
//           <div className="p-4 text-center">
//             {branchNames.map((name, index) => (
//               <div 
//                 key={index} 
//                 className="p-2 mb-2 border rounded bg-light branch-name-box"
//                 onClick={() => handleBranchClick(name)}
//               >
//                 {name}
//               </div>
//             ))}
//           </div>
//         </div>
//         <div className='col-5'>
//         {selectedBranchData.map(data => (
//             <div key={data._id} className="card p-3 mb-3">
//               <h5>{data.customerName}</h5>
//               <p>Date: {data.Date}</p>
//               <p>Status: {data.status}</p>
//              <div className='d-flex'>
//              <button className="btn btn-success me-2 " onClick={() => handleApprove(data._id)}>Approve</button>
//               <button className="btn btn-info me-2 g" onClick={() => handleView(data._id)}>View</button>
//               <button className="btn btn-danger" onClick={() => handleClose(data._id)}>Close</button>
//              </div>
//             </div>
//           ))}
//         </div>
//         <div col-8>
//         {/* <div className='col-8'>
//   {selectedFdBond && (
//     <div className="card p-4 shadow-lg" style={{ borderRadius: '10px', border: '1px solid #ccc', backgroundColor: '#f8f9fa' }}>
//       <h4 className="text-center mb-4">FD Bond Details</h4>
//       <p><strong>Customer Name:</strong> {selectedFdBond.customerName}</p>
//       <p><strong>FD Number:</strong> {selectedFdBond.fdNumber}</p>
//       <p><strong>Mobile Number:</strong> {selectedFdBond.mobileNumber}</p>
//       <p><strong>FD Amount:</strong> {selectedFdBond.fdAmount}</p>
//       <p><strong>Bond Percentage:</strong> {selectedFdBond.fdBondPercentage}%</p>
//       <p><strong>Bond Transfer Amount:</strong> {selectedFdBond.bondTransferAmount}</p>
//       <p><strong>Recipient Bank Account:</strong> {selectedFdBond.recipientBankAccount}</p>
//       <p><strong>Status:</strong> {selectedFdBond.status}</p>
//       <p><strong>Date:</strong> {selectedFdBond.Date}</p>
//       <p><strong>Time:</strong> {selectedFdBond.Time}</p>
//       {selectedFdBond.imageUrl && (
//         <div className="text-center">
//           <img src={`https://api.malabarbank.in/${selectedFdBond.imageUrl}`} alt="FD Bond" style={{ maxWidth: '100%', borderRadius: '10px' }} />
//         </div>
//       )}
//     </div>
//   )}
// </div> */}

//         </div>
//       </div>
//       <style>
//         {`
//           .branch-name-box:hover {
//             background-color: #d3d3d3;
//             cursor: pointer;
//           }

//              .card {
//       background-color: #ffffff;
//       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//     }
//     .card img {
//       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
//       margin-top: 20px;
//     }
//     .card h4 {
//       color: #007bff;
//     }
//         `}
//       </style>
//     </div>
//   );
// };

// export default FdBond;



import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const FdBond = () => {
  const [percentage, setPercentage] = useState(0);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchNames, setBranchNames] = useState([]);
  const [fdBranchReqData, setFdBranchReqData] = useState([]);
  const [selectedBranchData, setSelectedBranchData] = useState([]);
  const [pendingCounts, setPendingCounts] = useState({});

  useEffect(() => {
    axios.get("https://api.malabarbank.in/api/branches")
      .then((response) => {
        const branchData = response.data.map(branch => ({
          value: branch.branchCode,
          label: branch.branch_name
        }));
        setBranches(branchData);
        setBranchNames(response.data.map(branch => branch.branch_name));
      })
      .catch((error) => console.error("Error fetching branches:", error));

    axios.get("https://api.malabarbank.in/api/fdBranchReq")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setFdBranchReqData(response.data.data);
          calculatePendingCounts(response.data.data);
        } else {
          console.error("Expected an array from the API but got", typeof response.data);
        }
      })
      .catch((error) => console.error("Error fetching FD branch requests:", error));
  }, []);

  const calculatePendingCounts = (data) => {
    const counts = data.reduce((acc, curr) => {
      if (curr.status === "pending") {
        acc[curr.branch_name] = (acc[curr.branch_name] || 0) + 1;
      }
      return acc;
    }, {});

    setPendingCounts(counts);
  };

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setPercentage(value);
    }
  };

  const handleSubmit = () => {
    if (!selectedBranch) {
      alert('Please select a branch.');
      return;
    }

    const postData = {
      percentage: percentage,
      branchCode: selectedBranch.value,
      branchName: selectedBranch.label
    };

    axios.post('https://api.malabarbank.in/api/fd-bond', postData)
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        alert('An error occurred. Please try again.');
      });
  };

  const handleBranchClick = (branchName) => {
    const filteredData = fdBranchReqData.filter(data => data.branch_name === branchName);
    setSelectedBranchData(filteredData);
  };

  const handleApprove = (id) => {
    alert(`Approved FD Bond with ID: ${id}`);
  };

  const handleClose = (id) => {
    setSelectedBranchData(prev => prev.filter(data => data._id !== id));
  };

  return (
    <div>
      <div className='row'>
        <div className='col-3' style={{ backgroundColor: '#e9ecef', overflowY: 'scroll' }}>
          <div className="d-flex justify-content-center mt-5">
            <div className="card p-4 shadow-lg" style={{ width: '300px', borderRadius: '10px', border: '1px solid #ccc', backgroundColor: '#ffffff' }}>
              <h4 className="text-center mb-4">FD Bond Percentage</h4>
              <div className="input-group mb-3">
                <input 
                  type="number" 
                  className="form-control" 
                  value={percentage} 
                  onChange={handleInputChange}
                  placeholder="Enter Percentage"
                  min="0"
                  style={{ height: '38px' }} // Matching height of React Select
                />
              </div>
              <Select
                className="mb-3"
                value={selectedBranch}
                onChange={setSelectedBranch}
                options={branches}
                placeholder="Select Branch"
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '38px' // Matching height of the input field
                  })
                }}
              />
              <button className="btn btn-primary w-100" onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
        <div className='col-9'>
          <div className='container mt-4'  style={{ height: '790px',width: '1250px' }}>
            <div className='border p-3' style={{ height: '790px',width: '1250px' }}>
              <h2 className='text-center mb-4'>FD Bond Requests</h2>
              <div className='row' style={{ height: '100%', display: 'flex' }}>
                <div className='col-3' style={{ height: '93%', overflowY: 'scroll',backgroundColor: '#f0f2f5' }}>
                  <div className="p-4 text-center">
                    <h4 className="mb-3 ">Select Branch</h4>
                    {branchNames.length === 0 ? (
                      <p>No branches available</p>
                    ) : (
                      branchNames.map((name, index) => (
                        <div 
                          key={index} 
                          className="p-2 mb-2 border rounded bg-light branch-name-box"
                          onClick={() => handleBranchClick(name)}
                        >
                          {name} 
                          {pendingCounts[name] && (
                            <span className="badge bg-danger ms-2" style={{width:'40px',height:'auto'}}>{pendingCounts[name]}</span>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div className='col-9' style={{ height: '100%', overflowY: 'scroll' }}>
                  {selectedBranchData.length === 0 ? (
<div  style={{marginTop:'300px',marginLeft:'250px'}}>
<h3 className='justify-content-center'>No FD Bond data available</h3>

</div>
) : (
                    selectedBranchData.map(data => (
                      <div key={data._id} className="card p-3 mb-3">
                        <div className='d-flex'>
                          <div className='col-6'>
                            <p><strong>Customer Name:</strong> {data.customerName}</p>
                            <p><strong>Date:</strong> {data.Date}</p>
                            <p><strong>Status:</strong> {data.status}</p>
                            <p><strong>FD Number:</strong> {data.fdNumber}</p>
                            <p><strong>Mobile Number:</strong> {data.mobileNumber}</p>
                            <p><strong>FD Amount:</strong> {data.fdAmount}</p>
                            <p><strong>Bond Percentage:</strong> {data.fdBondPercentage}%</p>
                            <p><strong>Bond Transfer Amount:</strong> {data.bondTransferAmount}</p>
                            <p><strong>Recipient Bank Account:</strong> {data.recipientBankAccount}</p>
                            <p><strong>Status:</strong> {data.status}</p>
                            <p><strong>Date:</strong> {data.Date}</p>
                            <p><strong>Time:</strong> {data.Time}</p>
                          </div>
                          <div className='col-6 ' >
                            {data.image && (
                              <img src={data.image} alt="FD Bond" style={{ maxWidth: '100%', height: 'auto' }  }/>
                            )}
                          </div>
                        </div>
                        <div className='d-flex'>
                          <button className="btn btn-success me-2" onClick={() => handleApprove(data._id)}>Approve</button>
                          <button className="btn btn-danger" onClick={() => handleClose(data._id)}>Close</button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .branch-name-box:hover {
            background-color: #d3d3d3;
            cursor: pointer;
          }
          .badge {
            font-size: 0.75rem;
            padding: 0.25em 0.5em;
          }
          .card {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          }
          .card img {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            margin-top: 20px;
          }
          .card h4 {
            color: #007bff;
          }
        `}
      </style>
    </div>
  );
};

export default FdBond;
