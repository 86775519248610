import React,{useState} from 'react'
import { Modal, Table,Button } from 'react-bootstrap';
import SwarnaEmiDetails from './SwarnaEmiDetails';

function SwarnaGroupMembersModal({ show, onHide, groupData }) {
    const [showEmiDetails, setShowEmiDetails] = useState(false);
  return (
   <>
    <Modal show={show} onHide={onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Group Members - {groupData?.groupName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>SL NO</th>
            <th>Customer Name</th>
            <th>Membership ID</th>
            <th>Scheme ID</th>
            <th>Branch Code</th>
          </tr>
        </thead>
        <tbody>
          {groupData?.members.map((member, index) => (
            <tr key={member._id}>
              <td>{index + 1}</td>
              <td>{member.customerName}</td>
              <td>{member.membershipId}</td>
              <td>{member.schemeId}</td>
              <td>{member.branchCode}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal.Body>
    <Modal.Footer>
          <Button variant="primary" onClick={() => setShowEmiDetails(true)}>
            EMI Details
          </Button>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
  </Modal>
   {/* <swarnaEmiDetails
    show={showEmiDetails}
    onHide={() => setShowEmiDetails(false)}
    groupId={groupData?._id}
   
   /> */}
      {showEmiDetails && (
                <SwarnaEmiDetails
                    show={showEmiDetails}
                    onHide={() => setShowEmiDetails(false)}
                    groupData={groupData}
                />
            )}
   </>
  )
}

export default SwarnaGroupMembersModal