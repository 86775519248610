import React, { useState, useContext, useEffect, useRef } from "react";
import { Container, Form, Button } from "react-bootstrap";
import Nav from "../Others/Nav";
import axios from "axios";
import { UserContext } from "../Others/UserContext";
import moment from "moment";
import "../style/Main.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SwarnaNidhiPassbook() {
  const [accountNumbers, setAccountNumbers] = useState([]);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedAccountNumber, setSelectedAccountNumber] = useState("");
  const [schemeID, setSchemeID] = useState('');

  const [formData, setFormData] = useState({
    accountNumber: "",
    customerName: "",
    customerNumber: "",
    amount: "",
    depositwords: "",
    transactionId: "",
    branchUser: "",
    userTime: "",
    branchCode: "",
    branchName: '',
  });

  // const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [installments, setInstallments] = useState([]);
  const [toDate, setToDate] = useState("");
  const branch = user?.branchDetails?.branchCode;
  const branchName = user?.branchDetails?.branch_name;

  useEffect(() => {
    fetchInstallmentData(schemeID);
  }, [schemeID]);

  const fetchInstallmentData = async (schemeId) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://api.malabarbank.in/api/installments/${schemeId}`);

      if (response.data && response.data.data) {
        setAccountNumbers(response.data.data);
        setInstallments(response.data.data);

      } else {
        console.error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching installment data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("Account Numbers:", installments);
  }, [installments]);


  const handleACCNumberSelect = (value) => {
    fetchData(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      schemeId: value,
    }));
    setSchemeID(value); 
    setShowDropdown(false);
    
  };
  const fetchData = (mobile) => {
    const filteredCustomers = accountNumbers.filter(
      (customer) => customer.schemeId === mobile
    );
    if (filteredCustomers.length > 0) {
      setFormData(filteredCustomers[0]);
      setSchemeID(filteredCustomers[0].schemeId);
      setSelectedAccountNumber(filteredCustomers[0].schemeId);
    } else {
      setFormData(null);
    }
  };
  const filteredTransactions = Array.isArray(installments)
  ? installments.filter(
      (installment) => installment.schemeId === schemeID
    )
  : [];
  console.log("Filtered Transactions:", filteredTransactions);

  console.log("Filtered Transactions:", filteredTransactions);
  const DatefilteredTransactions = installments.filter(
    (transaction) => transaction.schemeId === selectedAccountNumber
  );

  const printAreaRef = useRef();

  const handlePrint = () => {
    const originalContents = document.body.innerHTML;
    const printContents = `
        <style>
          @media print {
            @page {
              size: A4 ;
            }
            body {
              width: 100%; 
            }
            .print-area {
              width: 90%;
              padding-top: 5px;
  
            }
          }
        </style>
        <div class="print-area">
          ${printAreaRef.current.innerHTML}
        </div>`;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  const confirmPrint = () => {
    toast(
      ({ closeToast }) => (
        <div>
          Are you sure you want to print?
          <div style={{ marginTop: '10px' }}>
            <Button
              variant="primary"
              onClick={() => {
                handlePrint();
                closeToast();
              }}
              style={{ marginRight: '10px' }}
            >
              Yes
            </Button>
            <Button variant="secondary" onClick={closeToast}>
              No
            </Button>
          </div>
        </div>
      ),
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      }
    );
  };
  const generateRows = () => {
    let overallIndex = 1;
    let rowCounter = 0;
    let dataCountInRange = 0;
    let totalDataCount = 0;
  
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    };
  
    const isDateInRange = (date, fromDate, toDate) => {
      const parsedDate = parseDate(date);
      const from = fromDate ? parseDate(fromDate) : null;
      const to = toDate ? parseDate(toDate) : null;
      return (!from || parsedDate >= from) && (!to || parsedDate <= to);
    };
  
    let earliestTransactionDate = Infinity;
    DatefilteredTransactions.forEach((transaction) => {
      if (transaction.date) {
        const entryDate = parseDate(transaction.date).getTime();
        if (entryDate < earliestTransactionDate) {
          earliestTransactionDate = entryDate;
        }
      }
      if (transaction.installments) {
        transaction.installments.forEach((data) => {
          const installmentDate = parseDate(data.date).getTime();
          if (installmentDate < earliestTransactionDate) {
            earliestTransactionDate = installmentDate;
          }
        });
      }
    });
  
    earliestTransactionDate = new Date(earliestTransactionDate);
  
    // Count total data entries before filtering
    DatefilteredTransactions.forEach((transaction) => {
      totalDataCount++;
      if (transaction.installments) {
        totalDataCount += transaction.installments.length;
      }
    });
  
    let dataCountOutsideRange = totalDataCount;
  
    // Function to add page breaks if needed
    const addPageBreakIfNeeded = (rows) => {
      if (rowCounter === 30) {
        rows.push(
          <tr key={`page-break-${overallIndex++}`} className="page-break print-hide" />
        );
        rowCounter = 0;
      }
    };
  
    // Generate transaction rows
    const transactionRows = DatefilteredTransactions.flatMap((transaction) => {
      const entryDate = transaction.date;
      const filteredInstallments = transaction.installments
        ? transaction.installments.filter((installment) =>
            isDateInRange(installment.date, fromDate, toDate)
          )
        : [];
      const isEntryInRange = isDateInRange(entryDate, fromDate, toDate);
  
      const rows = [];
      if (filteredInstallments.length === 0 && !isEntryInRange) {
        return rows;
      }
  
      // Initial total amount before deductions
      let remainingAmount;
  
      filteredInstallments.forEach((data, dataIndex) => {
        dataCountInRange++;
        dataCountOutsideRange--; // Decrease outside range count for each in-range entry
  
        remainingAmount =data.emiIndex * data.amount;
  
        addPageBreakIfNeeded(rows);
        rows.push(
          <tr className="trow" key={`${transaction._id}_installment_${dataIndex}`}>
            <td className="print-hide">{overallIndex++}</td>
            <td>{data.date}</td>
            <td>Installment</td>
            <td className="text-end">{data.emiIndex}</td> {/* Replacing withdrawalAmount with amount */}
            <td className="text-end">{data.amount}</td>
            <td className="text-end">{remainingAmount}</td> {/* Display remaining total */}
            <td></td>
          </tr>
        );
        rowCounter++;
      });
  
      return rows;
    });
  
    // Conditionally remove empty rows before generating the combined rows
    const combinedRows = transactionRows.length > 0 ? transactionRows : [];
    return combinedRows;
  };  

  const paginateRows = (rows, rowsPerPage = 20) => {
    const paginatedRows = [];
    for (let i = 0; i <= rows.length; i += rowsPerPage) {
      paginatedRows.push(rows.slice(i, i + rowsPerPage));
    }
    return paginatedRows;
  };

  const frontPageDetails = `
    <style>
      @media print {
        @page {
          size: A4;
          margin: 0; /* Remove default margin to prevent headers/footers */
        }
        body {
          margin: 0; /* Remove body margin to align with @page */
          width: 100%;
        }
        .front-page-details {
          width: 90%;
          padding-top: 5px;
          margin: auto;
          text-align: center; /* Center align all content */
        }
        .container {
          display: flex;
          justify-content: center; /* Center align columns */
        }
        .column {
          width: 45%;
          text-align: left; /* Reset text-align for content inside columns */
        }
        .row {
          display: flex;
          justify-content: left;
          align-items: center; /* Center align items vertically */
          margin-bottom: 2px; /* Default margin between rows */
        }
        .row.branch-address {
          flex-direction: column; /* Stack label and value vertically */
          align-items: flex-start; /* Align items to the start of the column */
          margin-bottom: 10px; /* Additional space below Branch Address */
        }
        .label {
          font-weight: bold;
          margin-right: 10px; /* Adjust margin between label and value */
        }
        .value {
          text-align: left; /* Right align value */
        }
          .spacer {
            visibility: hidden; /* Hide the content while keeping the space */
          }
      }
    </style>
    <div class="front-page-details">
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
        <p class="spacer">.</p>
      <div class="container">
        <div class="column">
          <div class="row branch-address">
            <span class="label">Branch Address:</span>
            <span class="value">${user?.branchDetails?.address || 'N/A'}</span>
          </div>
          <div class="row">
            <span class="label">IFSC Code:</span>
            <span class="value">${user?.branchDetails?.ifseCode || 'N/A'}</span>
          </div>
          <div class="row">
            <span class="label">Join Date:</span>
            <span class="value">${formData.date || formData.newDate || 'N/A'}</span>
          </div>
          <div class="row">
            <span class="label">Account Number:</span>
            <span class="value">${formData.schemeId || 'N/A'}</span>
          </div>
        </div>
        <div class="column">
          <div class="row">
            <span class="label">Membership ID:</span>
            <span class="value">${formData.membershipId || 'N/A'}</span>
          </div>
          <div class="row">
            <span class="label">Name:</span>
            <span class="value">${formData.customerName || 'N/A'}</span>
          </div>
          <div class="row">
            <span class="label">Mobile Number:</span>
            <span class="value">${formData.customerNumber || 'N/A'}</span>
          </div>
          <div class="row">
            <span class="label">Address:</span>
            <span class="value">${formData.address || 'N/A'}</span>
          </div>
        </div>
      </div>
    </div>
    `;

  const handlePrint2 = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(`
        <html>
        <head>
          <title>Print</title>
        </head>
        <body onload="window.print(); window.close();">
          ${frontPageDetails}
        </body>
        </html>
      `);
    printWindow.document.close();
    window.location.reload();
  };


  return (
    <div>
      <Nav />
      <Container>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "5rem",
          }}
        >
          <h3>PASSBOOK</h3>
        </div>

        <label>Scheme Id</label>
        <Form.Control
          type="text"
          placeholder="Search..."
          style={{ width: "23.5rem" }}
          value={schemeID}
          onChange={(e) => setSchemeID(e.target.value)}
          onFocus={() => setShowDropdown(true)}
        />
        {showDropdown && schemeID && (
          <ul className="dropdown-menu2">
            {accountNumbers
              .filter(
                (customer) =>
                  customer.schemeId &&
                  customer.schemeId
                    .toLowerCase()
                    .includes(schemeID.toLowerCase())
              )
              .map((customer, index) => (
                <li
                  key={index}
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleACCNumberSelect(customer.schemeId);
                    setShowDropdown(false);
                  }}
                >
                  {customer.schemeId} - {customer.customerName}
                </li>
              ))}
          </ul>
        )}
        <div style={{ textAlign: "center" }}>
          <div className="row mb-3">
            <div className="col-6">
              <label htmlFor="fromDate">From Date</label>
              <input
                type="date"
                className="form-control"
                id="fromDate"
                value={moment(fromDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  setFromDate(
                    moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
                  )
                }
              />
            </div>
            <div className="col-6">
              <label htmlFor="toDate">To Date</label>
              <input
                type="date"
                className="form-control"
                id="toDate"
                value={moment(toDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                onChange={(e) =>
                  setToDate(
                    moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
                  )
                }
              />
            </div>
          </div>

        </div>
        <button onClick={confirmPrint} className="btn btn-primary mb-3">
          <FontAwesomeIcon icon={faPrint} /> Print Passbook
        </button>
        <button className="btn btn-primary mb-3" onClick={handlePrint2}>Print Passbook Frontpage</button>

        <div ref={printAreaRef}>



          {paginateRows(generateRows()).map((rows, pageIndex) => (
            <div key={`page-${pageIndex}`}>
              <table className="table" id={`table-to-print-${pageIndex}`}>
                <thead>
                  <tr style={{ height: "2rem" }}>
                    <th className="print-hide">Index</th>
                    <th className="print-hide">Date</th>
                    <th className="print-hide">Particulars</th>
                    <th className="print-hide">Installment Number</th>
                    <th className="print-hide">Deposits</th>
                    <th className="print-hide">Total</th>
                    <th className="print-hide">Initials</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </table>
              {pageIndex < paginateRows(generateRows()).length - 1 && (
                <div className="page-break" />
              )}
            </div>
          ))}
        </div>
      </Container>
      <ToastContainer />
    </div>

  )
}

export default SwarnaNidhiPassbook