// // import React, { useState,useEffect  } from 'react';
// // import { Modal, Table, Button } from 'react-bootstrap';
// // import GroupEmiDetailsModal from './GroupEmiDetailsModal';

// // function swarnaEmiDetails({ show, onHide, groupId  }) {
// //     const [showEmiDetails, setShowEmiDetails] = useState(false);

// //     const [groupEmiDetails, setGroupEmiDetails] = useState({ members: [] });


// //   useEffect(() => {
// //     const fetchGroupEmiDetails = async () => {
// //       if (!groupId) return;

// //       try {
// //         const response = await fetch(`https://api.malabarbank.in/api/group/${groupId}`);
// //         if (!response.ok) {
// //           throw new Error("Failed to fetch group EMI details");
// //         }
// //         const data = await response.json();
// //         setGroupEmiDetails(data.data);
// //       } catch (error) {
// //         console.error("Failed to fetch group EMI details:", error);
// //       }
// //     };

// //     if (show) {
// //       fetchGroupEmiDetails();
// //     }
// //   }, [show, groupId]);

// //   const generateTableHeaders = () => {
// //     const headers = ['GDCS NUMBER', 'MEMBER NAME'];

// //     if (groupEmiDetails && Array.isArray(groupEmiDetails.members)) {
// //       const maxEmis = groupEmiDetails.members.reduce((max, member) => {
// //         const emiCount = member.monthlyEmi ? member.monthlyEmi.length : 0;
// //         return Math.max(max, emiCount);
// //       }, 0);
// //       for (let i = 1; i <= maxEmis; i++) {
// //         headers.push(`EMI ${i}`);
// //         headers.push(`DATE OF PAYMENT ${i}`);
// //       }
// //     }
// //     return headers;
// //   };

// //   const generateTableRows = () => {
// //     if (!Array.isArray(groupEmiDetails.members)) {
// //       return [];
// //     }
// //     return groupEmiDetails.members.map((member) => {
// //       const row = [member.GDCSNumber, member.customerName];
// //       if (Array.isArray(member.monthlyEmi)) {
// //         member.monthlyEmi.forEach((emiDetail) => {
// //           row.push(emiDetail.payableAmount);
// //           row.push(emiDetail.date);
// //         });
// //       }
// //       return row;
// //     });
// //   };

// //   return (
// //     <div>
// //      <Modal show={show} onHide={onHide} fullscreen>
// //       <Modal.Header closeButton>
// //         <Modal.Title>Group Member's EMI Details</Modal.Title>
// //       </Modal.Header>
// //       <Modal.Body style={{ maxWidth: '100%', overflowY: 'auto' }}>
// //         <Table striped bordered hover style={{ width: '100%', maxHeight: '500px', overflowY: 'auto' }}>
// //           <thead>
// //             <tr>
// //               {generateTableHeaders().map((header, index) => (
// //                 <th key={index}>{header}</th>
// //               ))}
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {generateTableRows().map((row, index) => (
// //               <tr key={index}>
// //                 {row.map((cell, cellIndex) => (
// //                   <td key={cellIndex}>{cell}</td>
// //                 ))}
// //               </tr>
// //             ))}
// //           </tbody>
// //         </Table>
// //       </Modal.Body>
// //       <Modal.Footer>
// //         <Button variant="secondary" onClick={onHide}>
// //           Close
// //         </Button>
// //       </Modal.Footer>
// //     </Modal>

// //     </div>
// //   )
// // }

// // export default swarnaEmiDetails


// import React, { useState, useEffect } from 'react';
// import { Modal, Table, Button } from 'react-bootstrap';
// // import SwarnaGroupMembersModal from './SwarnaGroupMembersModal';


// function SwarnaEmiDetails({ show, onHide, groupId }) {
//     const [showEmiDetails, setShowEmiDetails] = useState(false);
//     const [groupEmiDetails, setGroupEmiDetails] = useState({ members: [] });

//     useEffect(() => {
//         const fetchGroupEmiDetails = async () => {
//             if (!groupId) return;

//             try {
//                 const response = await fetch(`https://api.malabarbank.in/api/group/${groupId}`);
//                 if (!response.ok) {
//                     throw new Error("Failed to fetch group EMI details");
//                 }
//                 const data = await response.json();
//                 setGroupEmiDetails(data.data);
//             } catch (error) {
//                 console.error("Failed to fetch group EMI details:", error);
//             }
//         };

//         if (show) {
//             fetchGroupEmiDetails();
//         }
//     }, [show, groupId]);

//     const generateTableHeaders = () => {
//         const headers = ['GDCS NUMBER', 'MEMBER NAME'];

//         if (groupEmiDetails && Array.isArray(groupEmiDetails.members)) {
//             const maxEmis = groupEmiDetails.members.reduce((max, member) => {
//                 const emiCount = member.monthlyEmi ? member.monthlyEmi.length : 0;
//                 return Math.max(max, emiCount);
//             }, 0);
//             for (let i = 1; i <= maxEmis; i++) {
//                 headers.push(`EMI ${i}`);
//                 headers.push(`DATE OF PAYMENT ${i}`);
//             }
//         }
//         return headers;
//     };

//     const generateTableRows = () => {
//         if (!Array.isArray(groupEmiDetails.members)) {
//             return [];
//         }
//         return groupEmiDetails.members.map((member) => {
//             const row = [member.GDCSNumber, member.customerName];
//             if (Array.isArray(member.monthlyEmi)) {
//                 member.monthlyEmi.forEach((emiDetail) => {
//                     row.push(emiDetail.payableAmount);
//                     row.push(emiDetail.date);
//                 });
//             }
//             return row;
//         });
//     };

//     return (
//         <div>
//             <Modal show={show} onHide={onHide} fullscreen>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Group Member's EMI Details</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body style={{ maxWidth: '100%', overflowY: 'auto' }}>
//                     <Table striped bordered hover style={{ width: '100%', maxHeight: '500px', overflowY: 'auto' }}>
//                         <thead>
//                             <tr>
//                                 {generateTableHeaders().map((header, index) => (
//                                     <th key={index}>{header}</th>
//                                 ))}
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {generateTableRows().map((row, index) => (
//                                 <tr key={index}>
//                                     {row.map((cell, cellIndex) => (
//                                         <td key={cellIndex}>{cell}</td>
//                                     ))}
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </Table>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={onHide}>
//                         Close
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// }

// export default SwarnaEmiDetails;

import React, { useState, useEffect } from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

function SwarnaEmiDetails({ show, onHide, groupData }) {
    const [showEmiDetails, setShowEmiDetails] = useState(false);
    const [groupEmiDetails, setGroupEmiDetails] = useState(groupData);

    const generateTableHeaders = () => {
        const headers = ['Scheme ID', 'MEMBER NAME'];

        if (groupEmiDetails && Array.isArray(groupEmiDetails.members)) {
            const maxEmis = groupEmiDetails.members.reduce((max, member) => {
                const emiCount = member.installments ? member.installments.length : 0;
                return Math.max(max, emiCount);
            }, 0);
            for (let i = 1; i <= maxEmis; i++) {
                headers.push(`EMI ${i}`);
                headers.push(`DATE OF PAYMENT ${i}`);
            }
        }
        return headers;
    };

    const generateTableRows = () => {
        if (!Array.isArray(groupEmiDetails.members)) {
            return [];
        }
        return groupEmiDetails.members.map((member) => {
            const row = [member.schemeId, member.customerName];
            if (Array.isArray(member.installments)) {
                member.installments.forEach((emiDetail) => {
                    row.push(emiDetail.amount);
                    row.push(emiDetail.date);
                });
            }
            return row;
        });
    };

    return (
        <div>
            <Modal show={show} onHide={onHide} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Group Member's EMI Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxWidth: '100%', overflowY: 'auto' }}>
                    <Table striped bordered hover style={{ width: '100%', maxHeight: '500px', overflowY: 'auto' }}>
                        <thead>
                            <tr>
                                {generateTableHeaders().map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {generateTableRows().map((row, index) => (
                                <tr key={index}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SwarnaEmiDetails;

