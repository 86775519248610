// import React, { useState, useEffect } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Table from 'react-bootstrap/Table';
// import Button from 'react-bootstrap/Button';

// function SwarnaAuctionDetails({ show, onHide }) {
//   const [activeTab, setActiveTab] = useState('firstPrice');
//   const [apiData, setApiData] = useState([]); // Default to empty array
//   const [currentPage, setCurrentPage] = useState(1); // Track current page
//   const [totalPages, setTotalPages] = useState(1);   // Track total pages
//   const [limit, setLimit] = useState(1);             // Items per page

//   // Fetch paginated data from API
//   const fetchAuctionData = (page) => {
//     fetch(`https://api.malabarbank.in/api/getswarnaPaginated?page=${page}&limit=${limit}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setApiData(data.schemes || []); // Set to empty array if undefined
//         console.log("apiData",apiData)
//         setTotalPages(data.totalPages || 1); // Default to 1 if undefined
//       })
//       .catch((error) => console.error('Error fetching data:', error));
//   };

//   // Fetch data when modal opens and page changes
//   useEffect(() => {
//     if (show) {
//       fetchAuctionData(currentPage);
//     }
//   }, [show, currentPage]);

//   // Render table rows based on active tab
//   const renderTable = () => {
//     if (!apiData || apiData.length === 0) {
//       return (
//         <tr>
//           <td colSpan="5">No data available</td>
//         </tr>
//       );
//     }

//     return apiData.map((item, index) => (
//       <tr key={index}>
//         <td>{item.groupName || '-'}</td>
//         <td>{item.numberOfMembers || '-'}</td>
//         <td>{item.price || '-'}</td>
//         <td>{item.EMI || '-'}</td>
//         <td>{item.date || '-'}</td>
//       </tr>
//     ));
//   };

//   // Handle page change (next/previous)
//   const handlePageChange = (direction) => {
//     if (direction === 'next' && currentPage < totalPages) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     } else if (direction === 'prev' && currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   return (
//     <Modal show={show} onHide={onHide} size="lg">
//       <Modal.Header closeButton>
//         <Modal.Title>Auction Details</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="tabs">
//           <Button variant="primary" onClick={() => setActiveTab('firstPrice')}>First Price</Button>
//           <Button variant="secondary" onClick={() => setActiveTab('secondPrice')}>Second Price</Button>
//           <Button variant="success" onClick={() => setActiveTab('thirdPrice')}>Third Price</Button>
//         </div>
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>Customer Name</th>
//               <th>Customer Number</th>
//               <th>Membership ID</th>
//               <th>Scheme ID</th>
//               <th>Date</th>
//             </tr>
//           </thead>
//           <tbody>{renderTable()}</tbody>
//         </Table>

//         {/* Pagination Controls */}
//         <div className="pagination">
//           <Button
//             variant="outline-primary"
//             disabled={currentPage === 1}
//             onClick={() => handlePageChange('prev')}
//           >
//             Previous
//           </Button>
//           <span> Page {currentPage} of {totalPages} </span>
//           <Button
//             variant="outline-primary"
//             disabled={currentPage === totalPages}
//             onClick={() => handlePageChange('next')}
//           >
//             Next
//           </Button>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default SwarnaAuctionDetails;

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

function SwarnaAuctionDetails({ show, onHide }) {
  const [activeTab, setActiveTab] = useState('firstPrice'); // Default to 'firstPrice'
  const [apiData, setApiData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  // Fetch auction data
  const fetchAuctionData = (page) => {
    fetch(`https://api.malabarbank.in/api/getswarnaPaginated?page=${page}&limit=${limit}`)
      .then((response) => response.json())
      .then((data) => {
        setApiData(data.schemes || []); // Save the schemes data
        setTotalPages(data.totalPages || 1); // Set the total pages
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  // Fetch data when modal opens or page changes
  useEffect(() => {
    if (show) {
      fetchAuctionData(currentPage);
    }
  }, [show, currentPage]);

  // Function to get the auction details based on the active tab
  // const getActivePriceData = (item) => {
  //    console.log(item,"itemxxxx");  // Check each scheme object to see if firstPrice, secondPrice, or thirdPrice exists
  //   if (activeTab === 'firstPrice') return item.firstPrice;
  //   if (activeTab === 'secondPrice') return item.secondPrice;
  //   if (activeTab === 'thirdPrice') return item.thirdPrice;
  //   return [];
  // };

  const getActivePriceData = (scheme) => {
    if (!scheme.auctionDetails || scheme.auctionDetails.length === 0) {
      return [];
    }
    
    const activeDetails = scheme.auctionDetails[0];
    
    switch (activeTab) {
      case 'firstPrice':
        return activeDetails.firstPrice || [];
      case 'secondPrice':
        return activeDetails.secondPrice || [];
      case 'thirdPrice':
        return activeDetails.thirdPrice || [];
      default:
        return [];
    }
  };
  

  // Render table rows based on active tab
  // const renderTable = () => {
  //   if (!apiData || apiData.length === 0) {
  //     return (
  //       <tr>
  //         <td colSpan="5">No data available</td>
  //       </tr>
  //     );
  //   }

  //   return apiData.map((item, index) => {
  //     const activePriceData = getActivePriceData(item);

  //     if (!activePriceData || activePriceData.length === 0) {
  //       return (
  //         <tr key={index}>
  //           <td colSpan="5">No {activeTab} data available</td>
  //         </tr>
  //       );
  //     }

  //     return activePriceData.map((priceItem, priceIndex) => (
  //       <tr key={priceIndex}>
  //         <td>{priceItem.customerName || '-'}</td>
  //         <td>{priceItem.customerNumber || '-'}</td>
  //         <td>{priceItem.membershipId || '-'}</td>
  //         <td>{priceItem.schemeId || '-'}</td>
  //         <td>{priceItem.date || '-'}</td>
  //       </tr>
  //     ));
  //   });
  // };

  const renderTable = () => {
    if (!apiData || apiData.length === 0) {
      return (
        <tr>
          <td colSpan="5">No data available</td>
        </tr>
      );
    }
  
    return apiData.flatMap(scheme => {
      const activePriceData = getActivePriceData(scheme);
      
      if (!activePriceData || activePriceData.length === 0) {
        return null; // Return null instead of rendering a row
      }
  
      return activePriceData.map((priceItem, priceIndex) => (
        <tr key={`${scheme._id}-${priceIndex}`}>
          <td>{priceItem.customerName || '-'}</td>
          <td>{priceItem.customerNumber || '-'}</td>
          <td>{priceItem.membershipId || '-'}</td>
          <td>{priceItem.schemeId || '-'}</td>
          <td>{scheme.groupName || '-'}</td>
          <td>{priceItem.date || '-'}</td>
        </tr>
      ));
    });
  };
  

  // Handle page change (next/previous)
  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Auction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Tab buttons */}
        <div className="tabs mb-3">
          <Button variant="primary" className="me-1" onClick={() => setActiveTab('firstPrice')}>First Price</Button>
          <Button variant="secondary" className="me-1" onClick={() => setActiveTab('secondPrice')}>Second Price</Button>
          <Button variant="success" onClick={() => setActiveTab('thirdPrice')}>Third Price</Button>
        </div>

        {/* Auction Details Table */}
        {/* <Table striped bordered hover>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Customer Number</th>
              <th>Membership ID</th>
              <th>Scheme ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </Table> */}
        <Table striped bordered hover>
  <thead>
    <tr>
      <th>Customer Name</th>
      <th>Customer Number</th>
      <th>Membership ID</th>
      <th>Scheme ID</th>
      <th>Group Name</th>
      <th>Date</th>
    </tr>
  </thead>
  <tbody>{renderTable()}</tbody>
</Table>


        {/* Pagination Controls */}
        <div className="pagination">
          <Button
            variant="outline-primary"
            disabled={currentPage === 1}
            onClick={() => handlePageChange('prev')}
          >
            Previous
          </Button>
          <span> Page {currentPage} of {totalPages} </span>
          <Button
            variant="outline-primary"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange('next')}
          >
            Next
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SwarnaAuctionDetails;
