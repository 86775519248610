
// // import React, { useEffect, useState,useContext } from "react";
// // import { Button, Modal } from "react-bootstrap";
// // import axios from "axios";
// // import Select from 'react-select';
// // import { UserContext } from "../../Others/UserContext";

// // function SwarnaAuction({ show, onHide }) {

// //     const [groups, setGroups] = useState([]); // State to store fetched groups
// //     const [selectedGroupId, setSelectedGroupId] = useState(""); // State to manage the selected group ID
// //     const [query, setQuery] = useState(""); 
// //     const [phoneNumbers, setPhoneNumbers] = useState([""]);
// //     const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
// //     const [schemeIds, setSchemeIds] = useState([]);
// //     const [membershipId, setMembershipId] = useState('');
// // const [accountHolderName, setAccountHolderName] = useState('');
// // const [customerMobile, setCustomerMobile] = useState('');
// // const [selectedSchemeId, setSelectedSchemeId] = useState('');
// // const [selectedMemberDetails, setSelectedMemberDetails] = useState({
// //     membershipId: '',
// //     customerName: '',
// //     customerNumber: ''
// // });


  
// //     const [formData, setFormData] = useState({
// //       GroupName: "",
// //       duration: "",
// //       numberofMember: "",
// //       schemeType: "",
// //       emi: "",
// //       amount: "",
// //       interest: "",
// //       finalInterest: "",
// //       membershipId: "",
// //       customerName: "",
// //       customerNumber: "",
  
// //       referenceName: "",
// //       date: "",
// //       totalAmount: "",
// //       accountStatus: "",
// //       branchCode: "",
// //     });
  

// //      // Fetch groups when the component mounts
// //    // Fetch groups data from the API
// //    useEffect(() => {
// //     const fetchGroups = async () => {
// //       try {
// //         const response = await axios.get("https://api.malabarbank.in/api/getswarna");
// //         setGroups(response.data.schemes || []); // Set the groups data
// //         console.log("XXXX",groups)
// //       } catch (error) {
// //         console.error("Error fetching groups:", error);
// //       }
// //     };

// //     if (show) {
// //       fetchGroups();
// //     }
// //   }, [show]);
// //   // Handle group selection
// //   // const handleGroupChange = (e) => {
// //   //   setSelectedGroupId(e.target.value);
// //   //   // Additional logic can be added here if needed
// //   // };


// //   const handleGroupChange = (e) => {
// //     const selectedGroupId = e.target.value;
// //     setSelectedGroupId(selectedGroupId);
  
// //     // Find the selected group
// //     const selectedGroup = groups.find(group => group._id === selectedGroupId);
    
// //     if (selectedGroup) {
// //       // Extract schemeIds from the group's members
// //       const schemeIds = selectedGroup.members.map(member => member.schemeId).filter(id => id);
// //       setSchemeIds(schemeIds);
// //     } else {
// //       setSchemeIds([]);
// //     }
// //   };

// //   const handleSchemeChange = (selectedOption) => {
// //     const schemeId = selectedOption.value;
// //     setSelectedSchemeId(schemeId);

// //     // Find the member details based on the selected schemeId
// //     const selectedScheme = fetchedSchemes.find(scheme => scheme.schemeId === schemeId);

// //     if (selectedScheme) {
// //         // Assuming selectedScheme.members is an array of members for this scheme
// //         const memberDetails = selectedScheme.members.find(member => member.schemeId === schemeId);

// //         if (memberDetails) {
// //             setSelectedMemberDetails({
// //                 membershipId: memberDetails.membershipId,
// //                 customerName: memberDetails.customerName,
// //                 customerNumber: memberDetails.customerNumber
// //             });
// //         }
// //     }
// // };

  
// //   return (
// //         <div>
// //     <Modal show={show} dialogClassName="custom-modal-width">
// //       <Modal.Body className="p-0">
// //         <div className="Member form" style={{ maxWidth: "1800px" }}>
// //           <div className="card mt-0">
// //             <div className="card-header text-light">
// //               <h4>SWARNANIDHI AUCTION</h4>
// //             </div>
// //             <div className="card-body">
// //             <form 
// //             // onSubmit={handleSubmit}
// //             >

// //                 <div className="row">
// //                   <div className="col-6">
// //                     {/* All Groups */}
// //                     <div className="form-group">
// //                       <label htmlFor="allGroups">All Groups</label>
// //                       <select
// //                         className="form-control"
// //                         id="allGroups"
// //                         onChange={handleGroupChange}
// //                         value={selectedGroupId}
// //                         required
// //                       >
// //                         <option value="">Select Group</option>
// //                         {groups.map((group) => (
// //                           <option key={group._id} value={group._id}>
// //                             {group.groupName || "Unnamed Group"}
// //                           </option>
// //                         ))}
// //                       </select>
// //                     </div>
// //                     {/* Number of Members */}
// //                     <div className="form-group">
// //                       <label htmlFor="numberOfMembers">Number of Members</label>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         id="numberOfMembers"
// //                         name="numberOfMembers"
// //                         placeholder=""
// //                         readOnly
// //                         required
// //                         value={formData.numberofMember || "1000"}
// //                       />
// //                     </div>
// //                     {/* GDCS Number */}
// //                     <div className="form-group">
// //                       <label htmlFor="gdcsNumber">EMI Amount</label>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         id="EMI"
// //                         name="EMI"
// //                         placeholder="EMI Amount"
// //                         required
// //                         value={formData.emi || "1000"} // Default to 1000
// //                         readOnly
// //                       />
// //                     </div>
// //                     <div className="form-group">
// //                       <label htmlFor="CurrentDate">Current Date</label>
// //                       <input
// //                         type="date"
// //                         className="form-control"
// //                         required
// //                       />
// //                     </div>
// //                   </div>
// //                   <div className="col-6">
// //                     {/* Account Holder Name */}
// //                     <div className="form-group">
// //                       <label htmlFor="customerName">Account Holder Name</label>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         id="customerName"
// //                         name="customerName"
// //                         placeholder="Enter Account Holder Name"
// //                         required
// //                         readOnly
// //                         value={formData.customerName || ""}
// //                       />
// //                     </div>
// //                     {/* Customer Phone Number */}
                   
// //                     {/* <div className="form-group">
// //                         <div className="dropdown-wrapper">
// //                           <label htmlFor="customerName">
// //                             Customer Scheme Id
// //                           </label>
// //                           <Select
// // // value={selectedPhoneNumber?{ value: selectedPhoneNumber, label: selectedPhoneNumber } : null} 
// // //    onChange={handlePhoneNumberSelection}
// // //   onInputChange={(inputValue) => setQuery(inputValue)}
// // //   options={phoneNumbers}
// //   placeholder="Enter phone number"
// //   isClearable={true}
// //   required
// // />
// //                         </div>
// //                       </div> */}

// // <div className="form-group">
// //   <label htmlFor="customerSchemeId">Customer Scheme Id</label>
// //   <select
// //     className="form-control"
// //     id="customerSchemeId"
// //     value={formData.schemeId || ""}
// //     // onChange={(e) => setFormData({ ...formData, schemeId: e.target.value })}
// //     onChange={handleSchemeChange}
// //     required
// //   >
// //     <option value="">Select Scheme Id</option>
// //     {schemeIds.map((id, index) => (
// //       <option key={index} value={id}>
// //         {id}
// //       </option>
// //     ))}
// //   </select>
// // </div>

// //                     {/* Membership ID */}
// //                     <div className="form-group">
// //                       <label htmlFor="membershipId">Membership ID</label>
// //                       <input
// //                         type="text"
// //                         className="form-control"
// //                         id="membershipId"
// //                         name="membershipId"
// //                         placeholder="Enter Membership ID"
// //                         required
// //                         readOnly
// //                         value={formData.membershipId || ""}
// //                       />
// //                     </div>
// //                     {/* Reference Name */}
// //                     <div className="form-group">
// //                       <label htmlFor="referenceName">Reference Name</label>
// //                       <select
// //                         className="form-control"
// //                         name="referenceName"
                        
// //                       >
// //                         <option value="">Select an employee</option>
// //                         {/* Map employee options here */}
// //                       </select>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <div className="form-group">
// //                   <button type="submit" className="btn btn-primary">
// //                     Submit
// //                   </button>
// //                   <button type="reset" className="btn btn-secondary m-2">
// //                     Clear
// //                   </button>
// //                   <Button variant="danger" onClick={onHide}>
// //                     Close
// //                   </Button>
// //                 </div>
// //               </form>
// //             </div>
// //           </div>
// //         </div>
// //       </Modal.Body>
// //     </Modal>
// //   </div>
// //   )
// // }

// // export default SwarnaAuction


// // SwarnaAuction.jsx

// // 




// import React, { useEffect, useState } from "react";
// import { Button, Modal } from "react-bootstrap";
// import axios from "axios";

// function SwarnaAuction({ show, onHide }) {
//   const [groups, setGroups] = useState([]);
//   const [selectedGroupId, setSelectedGroupId] = useState("");
//   const [selectedGroup, setSelectedGroup] = useState(null);
//   const [schemeIds, setSchemeIds] = useState([]);
//   const [selectedSchemeId, setSelectedSchemeId] = useState("");
//   const [selectedMember, setSelectedMember] = useState(null);
//   const [selectedApi, setSelectedApi] = useState(""); // New state for API selection

//   useEffect(() => {
//     const fetchGroups = async () => {
//       try {
//         const response = await axios.get("https://api.malabarbank.in/api/getswarna");
//         setGroups(response.data.schemes || []);
//       } catch (error) {
//         console.error("Error fetching groups:", error);
//       }
//     };

//     if (show) {
//       fetchGroups();
//       setSelectedGroupId("");
//       setSelectedGroup(null);
//       setSchemeIds([]);
//       setSelectedSchemeId("");
//       setSelectedMember(null);
//       setSelectedApi(""); // Reset API selection when modal opens
//     }
//   }, [show]);

//   const handleGroupChange = (e) => {
//     const groupId = e.target.value;
//     setSelectedGroupId(groupId);

//     const group = groups.find((grp) => grp._id === groupId);
//     setSelectedGroup(group);

//     if (group) {
//       const schemes = group.members
//         .map((member) => member.schemeId)
//         .filter((id) => id);
//       setSchemeIds(schemes);
//     } else {
//       setSchemeIds([]);
//     }

//     setSelectedSchemeId("");
//     setSelectedMember(null);
//   };

//   const handleSchemeChange = (e) => {
//     const schemeId = e.target.value;
//     setSelectedSchemeId(schemeId);

//     if (selectedGroup) {
//       const member = selectedGroup.members.find(
//         (mem) => mem.schemeId === schemeId
//       );
//       setSelectedMember(member || null);
//     } else {
//       setSelectedMember(null);
//     }
//   };

//   const handleApiChange = (e) => {
//     setSelectedApi(e.target.value);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!selectedApi || !selectedGroupId) {
//       alert("Please select an API and a group.");
//       return;
//     }

//     const apiUrl = `https://api.malabarbank.in/api/${selectedApi}/${selectedGroupId}`;

//     try {
//       const response = await axios.post(apiUrl, {
//         // Include any additional payload data here
//         membershipId: selectedMember.membershipId,
//         customerName: selectedMember.customerName,
//         customerNumber: selectedMember.customerNumber,
//         schemeId: selectedSchemeId,
//       });

//       alert(response.data.message || "Request successful!");
//       onHide(); // Close the modal on success
//     } catch (error) {
//       console.error("Error submitting data:", error);
//       alert("There was an error submitting the data.");
//     }
//   };

//   return (
//     <Modal show={show} onHide={onHide} dialogClassName="custom-modal-width">
//       <Modal.Header closeButton>
//         <Modal.Title>SWARNANIDHI AUCTION</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <form onSubmit={handleSubmit}>
//           <div className="row">
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label htmlFor="allGroups">All Groups</label>
//                 <select
//                   className="form-control"
//                   id="allGroups"
//                   onChange={handleGroupChange}
//                   value={selectedGroupId}
//                   required
//                 >
//                   <option value="">Select Group</option>
//                   {groups.map((group) => (
//                     <option key={group._id} value={group._id}>
//                       {group.groupName || "Unnamed Group"}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="form-group">
//             <label htmlFor="apiSelection">Select Price</label>
//             <select
//               className="form-control"
//               id="apiSelection"
//               onChange={handleApiChange}
//               value={selectedApi}
//               required
//             >
//               <option value="">Select Price</option>
//               <option value="addFirstPrice">Add First Price</option>
//               <option value="addSecondPrice">Add Second Price</option>
//               <option value="addThirdPrice">Add Third Price</option>
//             </select>
//           </div>
//               <div className="form-group">
//                 <label htmlFor="numberOfMembers">Number of Members</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="numberOfMembers"
//                   value={selectedGroup?.numberOfMembers || ""}
//                   readOnly
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="currentDate">Current Date</label>
//                 <input
//                   type="date"
//                   className="form-control"
//                   id="currentDate"
//                   value={new Date().toISOString().split("T")[0]}
//                   readOnly
//                 />
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="form-group">
//                 <label htmlFor="customerSchemeId">Customer Scheme ID</label>
//                 <select
//                   className="form-control"
//                   id="customerSchemeId"
//                   onChange={handleSchemeChange}
//                   value={selectedSchemeId}
//                   required
//                   disabled={!schemeIds.length}
//                 >
//                   <option value="">Select Scheme ID</option>
//                   {schemeIds.map((id) => (
//                     <option key={id} value={id}>
//                       {id}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               <div className="form-group">
//                 <label htmlFor="membershipId">Membership ID</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="membershipId"
//                   value={selectedMember?.membershipId || ""}
//                   readOnly
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="accountHolderName">Account Holder Name</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="accountHolderName"
//                   value={selectedMember?.customerName || ""}
//                   readOnly
//                 />
//               </div>

//               <div className="form-group">
//                 <label htmlFor="customerMobile">Customer Mobile</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="customerMobile"
//                   value={selectedMember?.customerNumber || ""}
//                   readOnly
//                 />
//               </div>
//             </div>
//           </div>

         

//           <div className="modal-footer">
//             <Button variant="primary" type="submit" disabled={!selectedMember || !selectedApi}>
//               Submit
//             </Button>
//             <Button variant="secondary" onClick={onHide}>
//               Close
//             </Button>
//           </div>
//         </form>
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default SwarnaAuction;




import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";

function SwarnaAuction({ show, onHide }) {
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [schemeIds, setSchemeIds] = useState([]);
  const [selectedSchemeId, setSelectedSchemeId] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedApi, setSelectedApi] = useState(""); // New state for API selection
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get("https://api.malabarbank.in/api/getswarna");
        setGroups(response.data.schemes || []);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    if (show) {
      fetchGroups();
      setSelectedGroupId("");
      setSelectedGroup(null);
      setSchemeIds([]);
      setSelectedSchemeId("");
      setSelectedMember(null);
      setSelectedApi(""); // Reset API selection when modal opens
      setCurrentDate(new Date().toISOString().split("T")[0]); // Set current date
    }
  }, [show]);

  const handleGroupChange = (e) => {
    const groupId = e.target.value;
    setSelectedGroupId(groupId);

    const group = groups.find((grp) => grp._id === groupId);
    setSelectedGroup(group);

    if (group) {
      const schemes = group.members
        .map((member) => member.schemeId)
        .filter((id) => id);
      setSchemeIds(schemes);
    } else {
      setSchemeIds([]);
    }

    setSelectedSchemeId("");
    setSelectedMember(null);
  };

  const handleSchemeChange = (e) => {
    const schemeId = e.target.value;
    setSelectedSchemeId(schemeId);

    if (selectedGroup) {
      const member = selectedGroup.members.find(
        (mem) => mem.schemeId === schemeId
      );
      setSelectedMember(member || null);
    } else {
      setSelectedMember(null);
    }
  };

  const handleApiChange = (e) => {
    setSelectedApi(e.target.value);
  };

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value);
  };

  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}/${month}/${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedApi || !selectedGroupId) {
      alert("Please select an API and a group.");
      return;
    }

    const apiUrl = `https://api.malabarbank.in/api/${selectedApi}/${selectedGroupId}`;
    
    const formattedDate = formatDate(currentDate);

    try {
      const response = await axios.post(apiUrl, {
        // Include any additional payload data here
        membershipId: selectedMember.membershipId,
        customerName: selectedMember.customerName,
        customerNumber: selectedMember.customerNumber,
        schemeId: selectedSchemeId,
        date: formattedDate, // Include formatted date in the payload
      });

      alert(response.data.message || "Request successful!");
      onHide(); // Close the modal on success
      window.location.reload() 

    } catch (error) {
      console.error("Error submitting data:", error);
      alert("There was an error submitting the data.");
    }
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="custom-modal-width">
      <Modal.Header closeButton>
        <Modal.Title>SWARNANIDHI AUCTION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="allGroups">All Groups</label>
                <select
                  className="form-control"
                  id="allGroups"
                  onChange={handleGroupChange}
                  value={selectedGroupId}
                  required
                >
                  <option value="">Select Group</option>
                  {groups.map((group) => (
                    <option key={group._id} value={group._id}>
                      {group.groupName || "Unnamed Group"}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="apiSelection">Select Price</label>
                <select
                  className="form-control"
                  id="apiSelection"
                  onChange={handleApiChange}
                  value={selectedApi}
                  required
                >
                  <option value="">Select Price</option>
                  <option value="addFirstPrice">Add First Price</option>
                  <option value="addSecondPrice">Add Second Price</option>
                  <option value="addThirdPrice">Add Third Price</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="numberOfMembers">Number of Members</label>
                <input
                  type="text"
                  className="form-control"
                  id="numberOfMembers"
                  value={selectedGroup?.numberOfMembers || ""}
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="currentDate">Current Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="currentDate"
                  value={currentDate}
                  onChange={handleDateChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="customerSchemeId">Customer Scheme ID</label>
                <select
                  className="form-control"
                  id="customerSchemeId"
                  onChange={handleSchemeChange}
                  value={selectedSchemeId}
                  required
                  // disabled={!schemeIds.length}
                >
                  <option value="">Select Scheme ID</option>
                  {schemeIds.map((id) => (
                    <option key={id} value={id}>
                      {id}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="membershipId">Membership ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="membershipId"
                  value={selectedMember?.membershipId || ""}
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="accountHolderName">Account Holder Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="accountHolderName"
                  value={selectedMember?.customerName || ""}
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="customerMobile">Customer Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  id="customerMobile"
                  value={selectedMember?.customerNumber || ""}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <Button variant="primary" type="submit" disabled={!selectedMember || !selectedApi}>
              Submit
            </Button>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SwarnaAuction;
